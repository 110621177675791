<template>
  <div>

    <validation-observer
      tag="div"
      v-for="(vItem, vItemIndex) in vItems"
      :ref="`${slotConfig.name}_${vItemIndex}`"
      :vid="`${slotConfig.name}_${vItemIndex}`"
      :key="vItem.bleID"
      class="mb-05"
    >
      <b-row>
        <b-col cols="12" class="d-flex">
          <div class="flex-grow-1 d-flex" v-b-tooltip.hover.top="vItem.value.deviceRemark ? vItem.value.deviceRemark : null">
            <b-input-group :id="`${slotConfig.name}_${vItemIndex}_itemText`">
              <b-form-input
                type="text"
                :ref="`${slotConfig.name}_${vItemIndex}_itemText`"
                class="wh-font-family-monospace"
                :class="slotConfig.input_class"
                :value="vItemsText[vItemIndex]"
                style="height:100%;background-color:transparent;"
                v-b-modal="`${slotConfig.name}_${vItemIndex}_modal`"
                readonly
              />
              <b-input-group-append>
                <b-button
                  v-b-modal="`${slotConfig.name}_${vItemIndex}_modal`"
                  variant="outline-primary"
                  style="height:100%;"
                >
                  <feather-icon icon="EditIcon"/>
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </div>
          <b-button
            v-if="slotConfig.show_del_btn"
            class="flex-shrink-0 ml-1"
            variant="outline-danger"
            @click="deleteItem(vItemIndex)"
            :disabled="vItems.length <= slotConfig.min_count"
          >
            <!--{{ $t('common.delete') }}-->
            <feather-icon icon="MinusCircleIcon"/>
          </b-button>
        </b-col>

        <!-- 檢測當前 item 是否符合規則 -->
        <validation-provider
          tag="div"
          #default="{ errors }"
          :ref="`${slotConfig.name}_${vItemIndex}_main`"
          :vid="`${slotConfig.name}_${vItemIndex}_main`"
          :name="$t(slotConfig.label)"
          :customMessages="slotConfig.custom_messages"
          :rules="{
            required: true,
            regex: regexBleID,
          }"
        >
          <b-col
            cols="12"
            v-show="errors.length !== 0 && (!slotConfig.check_duplicate || !checkIsItemDuplicate(vItemIndex))"
          >
            <input type="hidden" :value="vItem.value.bleID && vItem.value.deviceModelID ? vItem.value.bleID : ''"/>
            <small class="text-danger">{{ errors[0] }}</small>
          </b-col>
        </validation-provider>

        <!-- 檢測當前 item 與其他 items 之間是否存在重複 -->
        <validation-provider
          tag="div"
          :ref="`${slotConfig.name}_${vItemIndex}_sub`"
          #default="{ errors }"
          :vid="`${slotConfig.name}_${vItemIndex}_sub`"
          :name="$t(slotConfig.label)"
          :customMessages="{ length: slotConfig.duplicate_tips}"
          rules="length:0"
          v-if="slotConfig.check_duplicate"
        >
          <b-col
            cols="12"
            v-show="errors.length !== 0 && checkIsItemDuplicate(vItemIndex)"
          >
            <input type="hidden" :value="checkIsItemDuplicate(vItemIndex) ? '_' : ''"/>
            <small class="text-danger">{{ errors[0] }}</small>
          </b-col>
        </validation-provider>

      </b-row>


      <b-modal
        :id="`${slotConfig.name}_${vItemIndex}_modal`"
        :title="`${$t('common.edit')} - ${$t(slotConfig.label)}`"
        size="lg"
        :ok-title="$t('common.confirm')"
        centered no-fade no-close-on-backdrop ok-only static
        hide-header-close no-close-on-esc
        @hide="onHideModal(vItemIndex)"
      >
        <b-row>
          <b-col cols="12" md="4" lg="3">
            <label class="field-row-label" :for="`${slotConfig.name}_${vItemIndex}_modelTag`">
              型號標識 <span style="color: red">*</span>
            </label>
          </b-col>
          <b-col cols="12" md="8" lg="9">
            <v-select
              :id="`${slotConfig.name}_${vItemIndex}_modelTag`"
              label="bleName"
              :options="modelList"
              v-model="vItem.value.deviceModelID"
              :reduce="model => model.deviceModelID"
              :clearable="false"
              :searchable="false"
              class="mb-1"
              @input="onModelTagChange(vItemIndex, arguments[0])"
            >
              <template #option="{ bleName, name, purposes }">
                <!--<span>{{ bleName }}</span> <span style="opacity: 0.6"><{{ name }}></span>-->
                <span style="margin-right: .6rem">{{ bleName }}</span>
                <span style="opacity: 0.6">
                  {{ purposes.map(p => kioskUtils.purposeOpts.find(o => o.value === p).text).join(', ') }}
                </span>
              </template>
              <template #no-options>{{ $t('common.no_options') }}</template>
            </v-select>
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col cols="12" md="4" lg="3">
            <label class="field-row-label" :for="`${slotConfig.name}_${vItemIndex}_purposes`">
              類型
            </label>
          </b-col>
          <b-col
            cols="12" md="8" lg="9"
            class="d-flex justify-content-start flex-wrap"
            style="padding-top: calc(0.438rem + 1px); padding-bottom: calc(0.438rem + 1px); column-gap: 22px; row-gap: 12px;"
          >
            <template v-for="(purpose, _i) in purposes">
              <b-form-checkbox
                :id="`${slotConfig.name}_${vItemIndex}_purposes`"
                :checked="(vItem.value.deviceModelID && modelList.find(model => model.deviceModelID === vItem.value.deviceModelID))
                  ? modelList.find(model => model.deviceModelID === vItem.value.deviceModelID).purposes.includes(purpose.value)
                  : false"
                type="text" disabled
              >
                {{ purpose.text }}
              </b-form-checkbox>
            </template>
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col cols="12" md="4" lg="3">
            <label class="field-row-label" :for="`${slotConfig.name}_${vItemIndex}_name`">名稱</label>
          </b-col>
          <b-col cols="12" md="8" lg="9">
            <b-form-input
              :id="`${slotConfig.name}_${vItemIndex}_name`"
              :value="modelList.find(model => model.deviceModelID === vItem.value.deviceModelID) ? modelList.find(model => model.deviceModelID === vItem.value.deviceModelID).name : ''"
              type="text" disabled
            />
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col cols="12" md="4" lg="3">
            <label class="field-row-label" :for="`${slotConfig.name}_${vItemIndex}_brand`">品牌</label>
          </b-col>
          <b-col cols="12" md="8" lg="9">
            <b-form-input
              :id="`${slotConfig.name}_${vItemIndex}_brand`"
              :value="modelList.find(model => model.deviceModelID === vItem.value.deviceModelID) ? modelList.find(model => model.deviceModelID === vItem.value.deviceModelID).brand : ''"
              type="text" disabled
            />
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col cols="12" md="4" lg="3">
            <label class="field-row-label" :for="`${slotConfig.name}_${vItemIndex}_model`">型號</label>
          </b-col>
          <b-col cols="12" md="8" lg="9">
            <b-form-input
              :id="`${slotConfig.name}_${vItemIndex}_model`"
              :value="modelList.find(model => model.deviceModelID === vItem.value.deviceModelID) ? modelList.find(model => model.deviceModelID === vItem.value.deviceModelID).model : ''"
              type="text" disabled
            />
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col cols="12" md="4" lg="3">
            <label class="field-row-label" :for="`${slotConfig.name}_${vItemIndex}_remark`">型號備註</label>
          </b-col>
          <b-col cols="12" md="8" lg="9">
            <b-form-input
              :id="`${slotConfig.name}_${vItemIndex}_remark`"
              :value="modelList.find(model => model.deviceModelID === vItem.value.deviceModelID) ? modelList.find(model => model.deviceModelID === vItem.value.deviceModelID).modelRemark : ''"
              type="text" disabled
            />
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col cols="12" md="4" lg="3">
            <label class="field-row-label" :for="`${slotConfig.name}_${vItemIndex}_id`">
              ID <span style="color: red">*</span>
            </label>
          </b-col>
          <b-col cols="12" md="8" lg="9">
            <b-form-input
              :id="`${slotConfig.name}_${vItemIndex}_id`"
              :list="`${slotConfig.name}_${vItemIndex}_idList`"
              :disabled="vItem.value.deviceModelID === ''"
              v-model="vItem.value.bleID"
              @blur="() => onBleIdBlur(vItemIndex)"
              type="text"
              class="wh-font-family-monospace"
            />
            <datalist :id="`${slotConfig.name}_${vItemIndex}_idList`">
              <option
                v-for="opt in historicalIds.filter(opt => opt.deviceModelID === vItem.value.deviceModelID)"
                :label="opt.bleID" :value="opt.bleID"
              ></option>
            </datalist>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="4" lg="3">
            <label class="field-row-label" :for="`${slotConfig.name}_${vItemIndex}_deviceRemark`">ID 備註</label>
          </b-col>
          <b-col cols="12" md="8" lg="9">
            <b-form-input
              :id="`${slotConfig.name}_${vItemIndex}_deviceRemark`"
              :disabled="vItem.value.deviceModelID === ''"
              v-model="vItem.value.deviceRemark"
              @blur="() => updateValues(true)"
              type="text"
            />
          </b-col>
        </b-row>
      </b-modal>

    </validation-observer>

    <b-button
      v-if="slotConfig.show_add_btn" variant="outline-primary" @click="addItem()"
      :disabled="vItems.length >= slotConfig.max_count"
    >
      <!--{{ $t('common.add') }}-->
      <feather-icon icon="PlusCircleIcon"/>
    </b-button>

  </div>
</template>

<script>
import common from '@/common'
import kioskUtils from '@/libs/kiosk-utils'
import _ from 'lodash'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import '@core/utils/validations/validations'
import { getDuplicateItems, truncateString } from '@/libs/ez-utils'

export default {
  name: 'FieldModalFixedPeripherals',
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: '',
    },
    input_class: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => [],
    },
    rootItem: {
      type: Object,
      default: () => ({}),
    },
    modelList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      kioskUtils,
      autoIndex: 0,
      vItems: [],
      vItemDefaultValue: { // vItem 的空值
        "deviceModelID": "",
        "bleID": "",
        "deviceRemark": "",
      },
      slotConfig: Object.assign({
        // type: 'text',
        name: 'defaultName',
        label: 'defaultLabel',
        min_count: this.rootItem.rule?.split('|')
          .includes('required') ? 1 : 0,
        max_count: 1,
        check_duplicate: true,
        duplicate_tips: this.$t('common.duplicate_content_tips'),
        show_del_btn: false,
        show_add_btn: false,
      }, this.rootItem.slot_config),
      purposes: kioskUtils.purposeOpts,
      historicalIds: [],

      // 6 到 16 bit 的十六進制字符串，bit 之間可以全帶半角冒號
      regexBleID: /^(?:[A-Fa-f0-9]{2}:){2,7}[A-Fa-f0-9]{2}$/,
    }
  },

  computed: {
    vItemsText() {
      return this.vItems.map(vItem => {
        let [bleName, mac, purposesStr] = ['', '', ''];
        const macLength = 13;
        mac = vItem.value.bleID
          ? truncateString(vItem.value.bleID, macLength, 2).padEnd(macLength, ' ')
          : ' '.repeat(macLength);
        if (vItem.value.deviceModelID) {
          const model = this.modelList.find(m => m.deviceModelID === vItem.value.deviceModelID);
          if (model) {
            const bleNameLength = 10;
            bleName = model.bleName
              ? truncateString(model.bleName, bleNameLength).padEnd(bleNameLength, ' ')
              : ' '.repeat(bleNameLength)
            purposesStr = model.purposes.map(p => kioskUtils.purposeOpts.find(o => o.value === p).text).join(' ');
          }
          return `${mac} | ${bleName} | ${purposesStr}`;
        }
        return '';
      })
    },
    modelTagOptions: {
      get() {
        return common.getSelectOptions(this.modelList.filter(m => m.bleName && m.deviceModelID), 'bleName', 'deviceModelID')
      }
    },
    duplicateItems: {
      get() {
        return this.slotConfig.check_duplicate
          ? getDuplicateItems(this.vItems.map(({ value: v }) => `${v.bleID} | ${v.deviceModelID}`))
          : []
      },
    },
  },

  watch: {
    items: {
      handler(items, _oldValues) {
        this.initItems()
      },
      deep: true,
    },
  },

  created() {
    this.initItems()
    common.apiGetData(common.getTableUrl(
      '/kioskmanage/GetHistoricalDevices',
      undefined, {}, {
        kioskToken: this.id,
        pageIndex: 0,
        pageSize: 999
      }))
      .then(({ data }) => this.historicalIds = _.uniqBy(data.devices, 'deviceModelID'))
  },

  methods: {
    initItems() {
      // 當 items 的值發生變化時，會先刪除多餘的 modelItems，再新增缺少的 modelItems
      for (const [i, item] of this.items.entries()) {
        while (this.vItems.length >= i + 1 && !_.isEqual(item, this.vItems[i].value)) {
          this.deleteItem(i, false)
        }
        if (this.vItems.length < i + 1) {
          this.addItem(_.clone(item))
          // this.addItem(_.pick(item, Object.keys(this.vItemDefaultValue)))
        }
      }
    },
    addItem(value, isUpdate = true) {
      this.vItems.push({
        value: value === undefined ? _.clone(this.vItemDefaultValue) : value,
        id: this.autoIndex,
      })
      this.autoIndex += 1
      isUpdate && this.updateValues()
    },
    deleteItem(itemIndex, isUpdate = true) {
      this.vItems.splice(itemIndex, 1)
      isUpdate && this.updateValues()
    },
    checkIsItemDuplicate(itemIndex) {
      const { value: v } = this.vItems[itemIndex]
      return this.duplicateItems.includes(`${v.bleID} | ${v.deviceModelID}`)
    },
    onModelTagChange(itemIndex, modelTag) {
      this.vItems[itemIndex].value.bleID = ''
      this.vItems[itemIndex].value.deviceRemark = ''
      this.updateValues(true)
    },
    onBleIdBlur(itemIndex) {
      const snRaw = this.vItems[itemIndex].value.bleID
      if (!this.regexBleID.test(snRaw)) return this.updateValues(true)

      const snConverted = snRaw.toUpperCase()
      if (snRaw === snConverted) return this.updateValues(true)

      this.vItems[itemIndex].value.bleID = snConverted
      common.showToast({
        title: common.getI18n('common.form_field_converted_tip'),
        variant: 'info',
      })
      this.updateValues(true)
    },
    updateValues(validate = false) {
      if (validate) {
        const refs = this.$refs
        const conf = this.slotConfig
        setTimeout(() => {
          this.vItems.forEach((v, i) => {
            refs[`${conf.name}_${i}_main`][0].validate()
            if (conf.check_duplicate) refs[`${conf.name}_${i}_sub`][0].validate()
          })
        }, 50) // to delay is to fix a UX bug
      }
      this.$emit('updateValues', _.cloneDeep(this.vItems.map(item => item.value)))
    },
    onHideModal(itemIndex) {
      // fix a UI bug
      setTimeout(() => this.$refs[`${this.slotConfig.name}_${itemIndex}_itemText`][0].$el.blur(), 50)
    },
  },
}
</script>

<style lang="scss" scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}

.field-row-label {
  padding: calc(0.438rem + 1px) 0;
}
</style>
