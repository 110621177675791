<template>
  <div class="wh-space-bottom">
    <b-card>
      <edit-field-header
        :title="title"
        @routerBack="$router.back()"
      />

      <edit-field
        :is-edit="true"
        :fields="fields"
        :row-data="row_data"
        post-api="/kioskmanage/EditKioskDevices"
        finish-router="kioskPeripheral"
        permission="Kiosk_Peripheral_Edit"
        :submit-action="submitAction"
      >
        <template v-slot:fixedPeripherals="scope">
          <slot-fixed-peripherals
            ref="slot-fixed-peripherals"
            :root-item="scope.rootItem"
            :items="row_data['devices']"
            :modelList="modelList"
            :id="id"
            @updateValues="updateFixedPeripherals"
          />
        </template>
      </edit-field>
    </b-card>
  </div>
</template>

<script>
import fields, { reloadFieldData } from './fields'
import EditField from '@/components/EditField2'
import EditFieldHeader from '@/components/EditFieldHeader'
import SlotFixedPeripherals from './FieldModalFixedPeripherals.vue'
import common from '@/common'
import kioskUtils from '@/libs/kiosk-utils'
import _ from 'lodash'
import { getDuplicateItems, truncateString } from '@/libs/ez-utils'

export default {
  name: 'Edit',
  components: {
    EditFieldHeader,
    EditField,
    fields,
    SlotFixedPeripherals,
  },

  data() {
    return {
      title: common.getMenuName('kioskPeripheral') + ' - ' + common.getI18n('common.edit'),
      fields,
      modelList: [],
      row_data: {
        fixedPurposes: [],
        devices: [],
      },
      orig_data: {
        fixedPurposes: [],
        devices: [],
      },
    }
  },

  computed: {
    id() {
      const id = this.$router.currentRoute.params.id
      return id ? common.decrypt(id) : undefined
    }
  },

  watch: {
    row_data: {
      handler(_value, _oldValues) {
        this.updatePurposes()
      },
      deep: true,
    },
  },

  created() {
    reloadFieldData()
  },

  mounted() {
    if (this.id === undefined) {
      this.$router.replace({ path: '/' + this.$route.path.split('/')[1] })
      return false
    }
    common.apiGetData('/kioskmanage/GetKioskDeviceDetail?kioskToken=' + this.id)
      .then(res => {
        const orig_data = {
          ...this.row_data,
          ...res.data.detail,
          devices: res.data.detail.devices?.map(device => ({
            // 篩選所需的字段
            // 爲了在用戶提交時比較是否有對 devices 作出修改，字段名需要與 `./FieldModalFixedPeripherals.vue` 中 `vItemDefaultValue` 的保持一致
            "deviceModelID": device.deviceModelID,
            "bleID": device.bleID.toUpperCase(),
            "deviceRemark": device.deviceRemark,
          })) ?? []
        }
        this.row_data = _.cloneDeep(orig_data)
        this.orig_data = _.cloneDeep(orig_data)
        this.updatePurposes()
      })
    common.apiGetData(common.getTableUrl(
      '/kioskmanage/GetDeviceModels',
      undefined, {}, {
        pageIndex: 0,
        pageSize: 999,
      }))
      .then(({ data }) => {
        this.modelList = data.models
          .filter(m => !!m.bleName) // 篩選 “包含 bleName 的”
          .filter(m => m.purposes.some(p => kioskUtils.fixablePurposes.includes(p))) // 篩選 “可固定的”
        this.updatePurposes()
      })

  },

  methods: {
    updatePurposes() {
      if (this.modelList.length < 1) return

      const purposes = this.row_data.devices
        .filter(p => p.deviceModelID)
        .map(p => this.modelList.find(m => m.deviceModelID === p.deviceModelID)?.purposes || [])
        .flat();

      if (!_.isEqual(this.row_data.fixedPurposes, purposes)) {
        this.row_data.fixedPurposes = purposes
      }
    },
    updateFixedPeripherals(value) {
      this.row_data.devices = value
    },
    submitAction(rowData, api, finishRouter) {
      const that = this

      // 如果“外設模式”改成了“自由”，并且原本存在“固定外設ID”，
      const deviceMode = that.row_data['deviceMode']
      if (deviceMode === "Free" && that.orig_data['devices'].length > 0) {
        const token = this.row_data.kioskToken
        const tokenLabel = this.fields.find(f => f.name === 'kioskToken')?.label ?? ''
        const deviceModeLabel = this.fields.find(f => f.name === 'deviceMode')?.label ?? ''
        const deviceModeTag = common.getI18n(`kiosk.${deviceMode.toLowerCase()}`)
        const devicesLabel = this.fields.find(f => f.name === 'devices')?.label ?? ''
        common.showConfirm({
          title: `當前${tokenLabel} (${token}) 的${deviceModeLabel}將被設置為“${deviceModeTag}”，相關的所有“${devicesLabel}”將被清空`,
          confirm_fun: () => submit()
        })
      }

      // 否則
      else {
        // 檢查特定的編輯字段
        const fieldsToCheck = ['devices']
        const fieldsToConfirm = []
        fieldsToCheck.forEach(field => {
          if (!_.isEqual(that.orig_data[field], that.row_data[field])) {
            fieldsToConfirm.push(that.fields.find(f => f.name === field)?.label ?? '')
          }
        })

        // 不包含特定字段，直接提交
        if (fieldsToConfirm.length === 0) return submit()

        // 包含特定字段，提示確認
        common.showConfirm({
          title: common.getI18n('common.form_fields_edit_confirm_tip'),
          html: fieldsToConfirm.map(f => `<span>${f}</span>`).join(`;<br />`),
          confirm_fun: () => submit()
        })
      }

      function submit() {
        that.$requestWehealth({
          method: 'post',
          url: api,
          data: {
            "KioskToken": rowData.kioskToken,
            "DeviceMode": rowData.deviceMode,
            "Devices": rowData.deviceMode === 'Fixed'
              ? rowData.devices.map(d => ({
                "ModelID": d.deviceModelID,
                "BleID": d.bleID,
                "DeviceRemark": d.deviceRemark,
              }))
              : [] // 自由模式，清空固定外設ID
          }
        })
          .then(() => that.$router.push({ name: finishRouter }))
          .catch((err) => that.handleSubmitError(err))
      }
    },
    handleSubmitError(err) {
      const { data, result_msg, return_code } = err.cause?.respond
      let html
      if (result_msg === "VSKioskPeripheralIsUsed" && data?.list?.length > 0) {
        html = data.list.map(({ bleID, kioskToken, modelID }, i) => {
          const model = this.modelList.find(m => m.deviceModelID === modelID)
          const [id, name, token] = [
            bleID ? truncateString(bleID, 17, 2).toUpperCase().padEnd(17, ' ') : '',
            model?.bleName ?? '',
            kioskToken ?? '',
          ]
          return `<li class="wh-font-family-monospace" style="margin-bottom: .4rem;">`
            + `[${token}]<br />`
            + `${id} | ${name}`
            + `</li>`
        }).join('')
        html = `<ul style="text-align: left;margin-bottom: .2rem;">${html}</ul>`
      }

      // Show Alert
      common.showAlert({
        title: common.getI18n(`error.${result_msg}`),
        html,
      })
    },
  },
}
</script>

<style scoped>

</style>
