import common from '@/common'
import kioskUtils from '@/libs/kiosk-utils'

export function reloadFieldData() {
}

export default [
  {
    name: 'kioskToken',
    label: '設備 Token',
    type: 'text',
    rule: 'required',
    edit_disable: true,
  },
  {
    name: 'kioskName',
    label: '設備名稱',
    type: 'text',
    rule: 'required',
    edit_disable: true,
  },
  // {
  //   name: 'companyName',
  //   label: '公司',
  //   type: 'text',
  //   rule: 'required',
  //   edit_disable: true,
  // },
  // {
  //   name: 'facilityName',
  //   label: '院舍',
  //   type: 'text',
  //   rule: 'required',
  //   edit_disable: true,
  // },
  {
    name: 'deviceMode',
    label: '外設模式',
    type: 'select',
    rule: 'required',
    options: common.getSelectOptions([
      { name: common.getI18n('kiosk.fixed'), value: 'Fixed' },
      { name: common.getI18n('kiosk.free'), value: 'Free' },
    ], 'name', 'value'),
  },
  {
    name: 'devices',
    label: '固定外設ID',
    type: 'slot',
    show_fn: (vm) => vm.rowData['deviceMode'] === 'Fixed',
    slot: 'fixedPeripherals',
    slot_config: {
      name: 'devices',
      label: '固定外設',
      max_count: 15,
      show_del_btn: true,
      show_add_btn: true,
    },
  },
  {
    name: 'fixedPurposes',
    label: '固定外設類型（合計）',
    type: 'checkboxFlow',
    show_fn: (vm) => vm.rowData['deviceMode'] === 'Fixed',
    options: kioskUtils.fixablePurposeOpts,
    edit_disable: true,
    add_disable: true,
  },
]
